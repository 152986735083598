<template>
  <div class="full-window">
    <home-header />
    <canvas-bg />
    <div class="left">
      <div class="indexPanel">
        <RouterView />
      </div>
      <p>Copyright © 臺北流行音樂中心 版權所有</p>
    </div>
    <div class="right">
      <div class="indexSwiper">
        <home-swiper></home-swiper>
      </div>
    </div>
  </div>
</template>

<script>
import canvasBg from '../Home/CanvasBg.vue';
import homeSwiper from '../Home/HomeSwiper.vue';
import homeHeader from '../Home/HomeHeader.vue';
import '@/assets/scss/index.scss';

export default {
  components: {
    homeSwiper,
    canvasBg,
    homeHeader,
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
