<template>
    <div class="canvasBg">
        <canvas class="orb-canvas"></canvas>
    </div>
</template>

<script>
import * as PIXI from 'pixi.js';
// import { KawaseBlurFilter } from '@pixi/filter-kawase-blur';
import SimplexNoise from 'simplex-noise';
import debounce from 'debounce';

// let app = '';

export default {
  components: {

  },
  data() {
    return {
      pixiApp: '',
    };
  },
  methods: {
    // TEST
    createOrb() {
      this.pixiApp = new PIXI.Application({
        // render to <canvas class="orb-canvas"></canvas>
        view: document.querySelector('.orb-canvas'),
        // setting canvas background color
        background: '#fff',
        // auto adjust size to fit the current window
        resizeTo: document.querySelector('.canvasBg'), // window,
        // transparent background, we will be creating a gradient background later using CSS
        // ???沒作用 待研究
        transparent: true,
      });

      // return a random number within a range
      function random(min, max) {
        return Math.random() * (max - min) + min;
      }

      // map a number from 1 range to another
      function map(n, start1, end1, start2, end2) {
        return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
      }

      const simplex = new SimplexNoise();

      class Orb {
        // Pixi takes hex colors as hexidecimal literals (0x rather than a string with '#')
        constructor(fill = 0x000000, startX = 200, startY = 200) {
          // 定義起始位置
          this.startX = startX;
          this.startY = startY;
          // bounds = the area an orb is "allowed" to move within
          this.bounds = this.setBounds();
          // initialise the orb's { x, y } values to a random point within it's bounds
          this.x = random(this.bounds.x.min, this.bounds.x.max);
          this.y = random(this.bounds.y.min, this.bounds.y.max);

          // how large the orb is vs it's original radius (this will modulate over time)
          this.scale = 1;

          // what color is the orb?
          this.fill = fill;

          // the original radius of the orb, set relative to window height
          this.radius = random(window.innerHeight / 6, window.innerHeight / 3);

          // starting points in "time" for the noise/self similar random values
          this.xOff = random(0, 1000);
          this.yOff = random(0, 1000);
          // how quickly the noise/self similar random values step through time
          this.inc = 0.002;

          // PIXI.Graphics is used to draw 2d primitives (in this case a circle) to the canvas
          this.graphics = new PIXI.Graphics();
          this.graphics.alpha = 0.60;

          // 250ms after the last window resize event, recalculate orb positions.
          window.addEventListener(
            'resize',
            debounce(() => {
              this.bounds = this.setBounds();
            }, 250),
          );
        }

        setBounds = () => {
          // how far from the { x, y } origin can each orb move
          // const maxDist = window.innerWidth / 20; // window.innerWidth < 1000 ? window.innerWidth / 6 : window.innerWidth / 8;
          // the { x, y } origin for each orb (the bottom right of the screen)
          // const originX = window.innerWidth / 1.25;
          // const originY = window.innerWidth < 1000
          //   ? window.innerHeight
          //   : window.innerHeight / 1.375;
          // mobile 100 / desktop 500
          const maxDist = window.innerWidth < 769 ? 100 : 500;
          const originX = this.startX;
          const originY = this.startY;

          // allow each orb to move x distance away from it's x / y origin
          return {
            x: {
              min: (originX - maxDist) < 0 ? 0 : originX - maxDist,
              max: (originX + maxDist) > window.innerWidth ? window.innerWidth : originX + maxDist,
            },
            y: {
              min: (originY - maxDist) < 0 ? 0 : originY - maxDist,
              max: originY + maxDist > window.innerHeight ? window.innerHeight : originY + maxDist,
            },
          };
        }

        update = () => {
          // self similar "psuedo-random" or noise values at a given point in "time"
          const xNoise = simplex.noise2D(this.xOff, this.xOff);
          const yNoise = simplex.noise2D(this.yOff, this.yOff);
          const scaleNoise = simplex.noise2D(this.xOff, this.yOff);

          // map the xNoise/yNoise values (between -1 and 1) to a point within the orb's bounds
          this.x = map(xNoise, -1, 1, this.bounds.x.min, this.bounds.x.max);
          this.y = map(yNoise, -1, 1, this.bounds.y.min, this.bounds.y.max);
          // map scaleNoise (between -1 and 1) to a scale value somewhere between half of the orb's original size, and 100% of it's original size
          this.scale = map(scaleNoise, -1, 1, 0.7, 1);
          // this.scale = 0.7 ~ 1;

          // step through "time"
          this.xOff += this.inc;
          this.yOff += this.inc;
        }

        render = () => {
          // update the PIXI.Graphics position and scale values
          this.graphics.x = this.x;
          this.graphics.y = this.y;
          this.graphics.scale.set(this.scale);

          // clear anything currently drawn to graphics
          this.graphics.clear();

          // tell graphics to fill any shapes drawn after this with the orb's fill color
          this.graphics.beginFill(this.fill);
          // draw a circle at { 0, 0 } with it's size set by this.radius
          this.graphics.drawCircle(0, 0, this.radius);
          // let graphics know we won't be filling in any more shapes
          this.graphics.endFill();
        }
      }

      // this.pixiApp.stage.filters = [new KawaseBlurFilter(30, 50, true)];
      // Create orbs
      const orbs = [];

      // for (let i = 0; i < 1; i += 1) {
      //   const orb = new Orb(colorPalette.randomColor());
      //   app.stage.addChild(orb.graphics);
      //   orbs.push(orb);
      // }

      const orbPorperty = [
        {
          color: 0x00BBD4,
          x: 100,
          y: 100,
        },
        {
          color: 0xff5000,
          x: window.innerWidth / 1.61, // 1185,
          y: window.innerHeight - 50,
        },
      ];
      orbPorperty.forEach((item) => {
        const orb = new Orb(item.color, item.x, item.y);
        this.pixiApp.stage.addChild(orb.graphics);

        orbs.push(orb);
      });

      // Animate!
      if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
        this.pixiApp.ticker.add(() => {
          // update and render each orb, each frame. app.ticker attempts to run at 60fps
          orbs.forEach((orb) => {
            orb.update();
            orb.render();
          });
        });
      } else {
        // perform one update and render per orb, do not animate
        orbs.forEach((orb) => {
          orb.update();
          orb.render();
        });
      }
    },

  },
  mounted() {
    this.createOrb();
  },
  unmounted() {
    this.pixiApp.destroy(true);
  },
  created() {
  },
};
</script>
