<template>
  <swiper
    :effect="'fade'"
    :autoplay="{
      delay: 2500,
      disableOnInteraction: false,
    }"
    :pagination="{
      clickable: true,
    }"
    :modules="modules"
    class="indexSwiper"
  >
  <swiper-slide>
    <img src="@/assets/images/banner-1.jpg" alt="">
  </swiper-slide>
  <swiper-slide>
    <img src="@/assets/images/banner-2.jpg" alt="">
  </swiper-slide>
  <swiper-slide>
    <img src="@/assets/images/banner-3.jpg" alt="">
  </swiper-slide>
  </swiper>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/effect-fade/effect-fade.min.css';

// import required modules
import { Autoplay, Pagination, EffectFade } from 'swiper';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay, Pagination, EffectFade],
    };
  },
};
</script>
